import { recupereInfoUtilisateur, ConnectedUser } from "../../../services/Authentification/Authentification"
import React, { Fragment, useEffect, useState } from "react"
import { ErreurAuthentification } from "../ErreurAuthentification/ErreurAuthentification"
import { UtilisateurCourantContext } from "./UtilisateurCourantContext"
import Supervision from "../../../services/Supervision/Supervision"

const ProvideUtilisateurCourant: React.FunctionComponent = ({ children }) => {
	const [userinfo, setUserinfo] = useState<ConnectedUser>()
	const [erreur, setErreur] = useState<string>()
	const [connectedUser, setConnectedUser] = useState<ConnectedUser>()

	useEffect(() => { quandLaRouteChange() }, [connectedUser])
	async function quandLaRouteChange () {
		if (!connectedUser) {
			await quandLUtilisateurNEstPasAuthentifié()
		} else {
			quandOnEstAuthentifié(connectedUser)
		}

		async function quandLUtilisateurNEstPasAuthentifié() {
			try {
				const {	response,	statusCode	} = await recupereInfoUtilisateur()
				if	(statusCode != 403) {
					setConnectedUser(response.data)
				}
			} catch (e) {
				setErreur("Impossible de procéder à l'authentification.")
			}
		}

		async function quandOnEstAuthentifié(connectedUser: ConnectedUser) {
			try {
				await Supervision().loggerLaConnexionDunUsager(connectedUser.sub,"none")
				setUserinfo(connectedUser)
			} catch (erreur) {
				setErreur(erreur.message)
			}
		}
	}

	return (
		<Fragment>
			{ erreur && <ErreurAuthentification description={ String(erreur) } /> }
			{ connectedUser && userinfo && (
				<UtilisateurCourantContext.Provider value={ connectedUser }>
					{ children }
				</UtilisateurCourantContext.Provider>
			) }
		</Fragment>
	)

}

export { ProvideUtilisateurCourant }
