import React, { ReactNode } from "react"
import { Fragment } from "react"
import Entête from "./Entête"
import SurPiedDePage from "./SurPiedDePage"
import PiedDePage from "./PiedDePage"
import BasPiedDePage from "./BasPiedDePage"
import "./LayoutPrincipal.css"

type LayoutPrincipalProps = {
	children: ReactNode,
	urlDeconnexion: string | null
}

const LayoutPrincipal: React.FunctionComponent<LayoutPrincipalProps> = ({ urlDeconnexion, children }: LayoutPrincipalProps ) => {
	return (
		<Fragment>
			<header 
				className="fr-header" 
				id="banner"
				role="banner" 
			>
				<div className="fr-container">
					<Entête />
				</div>
			</header>

			<div className="fr-container sp-bg-sm fr-mb-0 fr-pb-2w">
				<main 
					className="main" 
					id="main"
					role="main" 
				>
					{ urlDeconnexion && (
						<div className="url-deconnexion">
							<a
								className="fr-link"
								href={ urlDeconnexion }
								target="_self"
							>
								<span
									aria-hidden="true"
									className="fr-icon-logout-box-r-line">
								</span>
								Se déconnecter
							</a>
						</div>	
					) }

					<div className="fr-grid-row">
						<div className="fr-col-offset-md-1 fr-col-md-10 fr-col-offset-lg-3 fr-col-lg-6 fr-pt-2w">
							<h1 className="dile-titre-demarche fr-my-6w dile-text--center">
								Votre demande d’inscription sur les listes électorales
							</h1>
							{ children }
						</div>
					</div>					

				</main>
			</div>

			<footer 
				className="fr-footer" 
				id="footer"
				role="contentinfo" 
			>
				<h2 className="footer-title">Pied de page</h2>
				<SurPiedDePage />

				<div className="fr-container">
					<PiedDePage />
					<BasPiedDePage />
				</div>

				<p className="fr-sr-only">footer</p>
			</footer>

		</Fragment>
	)
}

export default LayoutPrincipal
