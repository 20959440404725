import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Demarche from "./pages/Demarche/Demarche"
import Accueil from "./pages/Accueil/Accueil"
import LoggedIn from "./pages/LoggedIn/LoggedIn"
import Confirmation from "./pages/Confirmation/Confirmation"
import DepotDePiecesJustificatives from "../services/DepotDePiecesJustificatives/DepotDePiecesJustificatives"
import DepotAxiosDePiecesJustificatives from "../services/DepotDePiecesJustificatives/DepotAxiosDePiecesJustificatives"
import MoteurDeRechercheDeDonneesReferentiel
	from "../services/MoteurDeRechercheDeDonneesReferentiels/MoteurDeRechercheDeDonneesReferentiel"
import MoteurDeRechercheDeDonneesReferentielAxios
	from "../services/MoteurDeRechercheDeDonneesReferentiels/MoteurDeRechercheDeDonneesReferentielAxios"
import GestionnaireDeDemarche from "../services/GestionnaireDeDemarche/GestionnaireDeDemarche"
import GestionnaireAxiosDeDemarche from "../services/GestionnaireDeDemarche/GestionnaireAxiosDeDemarche"
import ScrollEnDebutDePage from "./composants/ScrollEnDebutDePage/ScrollEnDebutDePage"
import LayoutPrincipalContextualisé from "./composants/LayoutPrincipalContextualisé/LayoutPrincipalContextualisé"
import ValidateurDeDemarche, {
	ValidateurDeDemarcheInterface,
	validateursParChamps
} from "../services/ValidateurDeDemarche/ValidateurDeDemarche"
import Formulaire from "./pages/Formulaire/Formulaire"
import Recapitulatif from "./pages/Recapitulatif/Recapitulatif"
import DonnerSonAvis from "./pages/DonnerSonAvis/DonnerSonAvis"
import DemarcheIndisponible from "./pages/DemarcheIndisponible/DemarcheIndisponible"
import LayoutPrincipalHorsConnexion from "./ui/LayoutPrincipal/LayoutPrincipalHorsConnexion"
import { ProvideUtilisateurCourant } from "./pages/Authentifié/ProvideUtilisateurCourant"

export const DepotDePiecesJustificativesContext = React.createContext<DepotDePiecesJustificatives>(DepotAxiosDePiecesJustificatives())
export const MoteurDeRechercheDeDonneesReferentielContext = React.createContext<MoteurDeRechercheDeDonneesReferentiel>(MoteurDeRechercheDeDonneesReferentielAxios())
export const GestionnaireDeDemarcheContext = React.createContext<GestionnaireDeDemarche>(GestionnaireAxiosDeDemarche())
export const ValidateurDeDemarcheContext = React.createContext<ValidateurDeDemarcheInterface>(ValidateurDeDemarche(validateursParChamps))

const App: React.FunctionComponent = () => {
	return (
		<div className="App">
			<DepotDePiecesJustificativesContext.Provider value={ DepotAxiosDePiecesJustificatives() }>
				<MoteurDeRechercheDeDonneesReferentielContext.Provider value={ MoteurDeRechercheDeDonneesReferentielAxios() }>
					<GestionnaireDeDemarcheContext.Provider value={ GestionnaireAxiosDeDemarche() }>
						<ValidateurDeDemarcheContext.Provider value={ ValidateurDeDemarche(validateursParChamps) }>
							<Router>
								<Switch>
									<Route
										exact
										path="/demarcheIndisponible">
										<LayoutPrincipalHorsConnexion>
											<DemarcheIndisponible/>
										</LayoutPrincipalHorsConnexion>
									</Route>
									<ProvideUtilisateurCourant>
										<LayoutPrincipalContextualisé>
											<Route
												exact
												path="/"
											>
												<Redirect to="/accueil"/>
											</Route>
											<Route
												exact
												path="/accueil"
											>
												<Accueil/>
											</Route>
											<Demarche>
												<ScrollEnDebutDePage/>
												<Switch>
													<Route
														exact
														path="/demarche">
														<Formulaire/>
													</Route>
													<Route
														exact
														path="/recapitulatif">
														<Recapitulatif/>
													</Route>
													<Route
														exact
														path="/confirmation">
														<Confirmation/>
													</Route>
													<Route
														exact
														path="/donnerSonAvis">
														<DonnerSonAvis/>
													</Route>
												</Switch>
											</Demarche>
											<Route
												path="/login">
												<LoggedIn/>
											</Route>
										</LayoutPrincipalContextualisé>
									</ProvideUtilisateurCourant>
								</Switch>
							</Router>
						</ValidateurDeDemarcheContext.Provider>
					</GestionnaireDeDemarcheContext.Provider>
				</MoteurDeRechercheDeDonneesReferentielContext.Provider>
			</DepotDePiecesJustificativesContext.Provider>
		</div>
	)
}

export default App
