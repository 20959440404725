import axios from "../Axios/AxiosApi"
import { BACKEND_URL } from "../configurationHttp"

export interface BaseResponse<T> { ok: boolean, data?: T }
export interface ConnectedUser { sub: string, email: string, userInformation: { [key: string]: string }, authenticationOrigin: string }
export type AuthMeResponse = BaseResponse<ConnectedUser>;

export function recupereInfoUtilisateur(): Promise<{ response: AuthMeResponse, statusCode: number }> {
	return axios.get(BACKEND_URL + "/authn/me")
		.then(response => {
			return { response: response.data, statusCode: response.status }
		})
		.catch(error => {
			if (error.response) {
				return { response: error.response, statusCode: error.response.status }
			} else {
				throw error
			}
		})
}

