import React, { Fragment, SyntheticEvent, useRef, useState } from "react"
import useComponentSize from "@rehooks/component-size"
import { animated, useTransition } from "react-spring"
import PieceJustificative, { PieceJustificativeProps } from "./PieceJustificative"
import ChampListeDeroulante, { Option } from "../../ui/ChampListeDeroulante/ChampListeDeroulante"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"
import { listeChoixTypeJustificatif } from "../../../services/ValidateurDeDemarche/validateursParChamp/ChoixTypeJustificatifValidation"
import CaseACocher from "../../ui/CaseACocher/CaseACocher"
import "./PieceJustificative.css"


export type choixTypeJustificatif = {
	choixTypeJustificatif: string
}

export type BlocPiecesJustificativesProps = {
	libelleLienExterne: string
	lienExterne: string
	listePiecesJustificatives: PieceJustificativeProps[]
	messageErreurFormulaire?: string
	messageAideALaSaisie1?: React.HTMLProps<any>
	messageAideALaSaisie2?: React.HTMLProps<any>
	texteAidePJ?: React.HTMLProps<any>
	titreBloc?: string
	typePieceJustificative: string
	choixTypeJustificatif: string
	mettreAJourLaValeurDuChoixDeTypeJustificatif: (valeurSelectionnee: Partial<choixTypeJustificatif>) => void
	validation?: Validation,
	required: boolean,
	auClickSurCaseACocherUnSeulFichierPJ: (event: React.ChangeEvent<HTMLInputElement>) => void,
	unSeulFichierPJ: boolean
}

const BlocPiecesJustificatives: React.FunctionComponent<BlocPiecesJustificativesProps> = ({
	libelleLienExterne,
	lienExterne,
	listePiecesJustificatives,
	messageErreurFormulaire,
	messageAideALaSaisie1,
	messageAideALaSaisie2,
	texteAidePJ,
	titreBloc = "Vos justificatifs",
	typePieceJustificative,
	mettreAJourLaValeurDuChoixDeTypeJustificatif,
	choixTypeJustificatif,
	validation,
	required,
	auClickSurCaseACocherUnSeulFichierPJ,
	unSeulFichierPJ
}: BlocPiecesJustificativesProps) => {

	const [ texteAideEstAffiche, setTexteAideEstAffiche ] = useState<boolean>(false)
	const refBulleDAide = useRef(null)
	const { height } = useComponentSize(refBulleDAide)
	const transitions = useTransition(texteAideEstAffiche, null, {
		from: {
			height: 0
		},
		enter: {
			height
		},
		leave: {
			height: 0
		},
		update: { height },
		config: { duration: 400 }
	})

	const optionsDisponibles: Option[] = [ ...listeChoixTypeJustificatif ]
	optionsDisponibles.unshift({ label: "Sélectionnez votre justificatif", valeur: "" })

	const afficherLeTexte = async (event: SyntheticEvent) => {
		event.preventDefault()
		setTexteAideEstAffiche(!texteAideEstAffiche)
	}
	const modifierChoix = (choix: string) => {
		mettreAJourLaValeurDuChoixDeTypeJustificatif({ choixTypeJustificatif: choix })
	}

	return (
		<div className="sp-justif">
			<div className="sp-justif-title">
				<p className="sp-demarche-step-recap-icone">
					<span
						aria-hidden="true"
						className="fr-icon-file-line fr-icon--lg"
					>
					</span>
				</p>
				<h3 className="fr-text fr-text--lg">{ titreBloc }
					{ texteAidePJ &&
					<button
						aria-controls={ "bulle-info-" + typePieceJustificative }
						aria-expanded={ texteAideEstAffiche }
						aria-label="Afficher/masquer les informations complémentaires"
						className="sp-btn-info fr-icon-information-line"
						onClick={ afficherLeTexte }
						title={ "Informations complémentaires : " + titreBloc }
						type="button"
					>
						<span className="fr-sr-only">Afficher/masquer les informations complémentaires</span>
					</button>
					}
				</h3>
			</div>
			{ transitions.map(
				({ item, props, key }) =>
					item && (
						<animated.div
							className="sp-btn-info-target"
							id={ "bulle-info-" + typePieceJustificative }
							key={ key }
							style={ {
								...props,
								overflow: "hidden",
								position: "relative"
							} }
						>
							<div
								ref={ refBulleDAide }
							>
								<div className="piece-justificative-texte-aide">
									<Fragment>{ texteAidePJ }</Fragment>
									<p className="dile-text--right">
										<a
											className="fr-link fr-fi-arrow-right-line fr-link--icon-right"
											href={ lienExterne }
											rel="noopener noreferrer"
											target="_blank"
											title={ `${ libelleLienExterne } - Nouvelle fenêtre` }
										>
											{ libelleLienExterne }
										</a>
									</p>
								</div>
							</div>
						</animated.div>
					))
			}
			<div className="sp-justif-content">
				{ messageAideALaSaisie1 }

				{ typePieceJustificative == "identite" &&
				<ChampListeDeroulante
					ariaDescribedBy = "idMessageAideALaSaisie2"
					ariaLabelledBy = "idMessageAideALaSaisie1"
					id={ "choixTypeJustificatif" }
					label={ "" }
					messageErreurFormulaire={ messageErreurFormulaire }
					modifierChoix={ modifierChoix }
					name={ "choixTypeJustificatif" }
					options={ optionsDisponibles }
					required={ required }
					valeurInitiale={ choixTypeJustificatif }
					validation={ validation }/>
				}

				{ messageAideALaSaisie2 }

				{ (choixTypeJustificatif !== "" || typePieceJustificative !== "identite") &&
				(
					<div className="fr-form-group">
						<div>

							{ ((listePiecesJustificatives.length === 1) || (listePiecesJustificatives[1].cacher)) && (
								<div className="piece-jointe-title">
									Transmettez votre fichier
								</div>
							) }
							{ ((listePiecesJustificatives.length > 1) && (!listePiecesJustificatives[1].cacher)) && (
								<div className="piece-jointe-title">
									Transmettez votre ou vos fichiers
								</div>
							) }
							{ ((listePiecesJustificatives.length > 1) && (unSeulFichierPJ || !listePiecesJustificatives[1].cacher)) && (
								<CaseACocher
									caseEstCochée={ unSeulFichierPJ }
									id={ "no-fusion-" + typePieceJustificative }
									label="Je transmets mes documents dans un seul fichier"
									onChange={ auClickSurCaseACocherUnSeulFichierPJ }
									texteAide="Facultatif"
									texteAvertissementDeLaMiseAJour={ "" }
									valeur="no-fusion"/>
							) }

							{ listePiecesJustificatives.map((pieceJustificative, index) =>
								<Fragment
									key={ index }>
									{ (index === 1) && ( <br/> ) }
									{ (!pieceJustificative.cacher) && (
										<PieceJustificative
											unSeulFichierPJ={ unSeulFichierPJ }
											{ ...pieceJustificative }
											validation={ validation }
										/>
									) }
								</Fragment>
							) }
						</div>
					</div>
				)
				}
			</div>
		</div>
	)
}

export default BlocPiecesJustificatives
