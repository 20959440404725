
import React, {
	Fragment,
	SyntheticEvent,
	useContext,
	useLayoutEffect,
	useRef,
	useState,
	useEffect
} from "react"
import BlocPiecesJustificatives from "../../composants/PieceJustificative/BlocPiecesJustificatives"
import ChampTextuel from "../../ui/ChampTextuel/ChampTextuel"
import MiseEnAvant from "../../ui/MiseEnAvant/MiseEnAvant"
import ChampsDuFormulaireDemarche from "../../../services/GestionnaireDeDemarche/types/ChampsDuFormulaireDemarche"
import NomDeFamilleValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/NomDeFamilleValidation"
import NomDUsageValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/NomDUsageValidation"
import DateDeNaissanceCompleteValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/DateDeNaissanceCompleteValidation"
import ChampBoutonsRadiosAvecValidation
	from "../../ui/ChampBoutonsRadiosAvecValidation/ChampBoutonsRadiosAvecValidation"
import ChampBoutonsRadios from "../../ui/ChampBoutonsRadios/ChampBoutonsRadios"
import SexeValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/SexeValidation"
import NationaliteValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/NationaliteValidation"
import CaseACocher from "../../ui/CaseACocher/CaseACocher"
import DateDeNaissanceIncompleteValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/DateDeNaissanceIncompleteValidation"
import ChampReferentielLieuDInscription
	from "../../composants/ChampReferentielLieuDInscription/ChampReferentielLieuDInscription"
import ChampReferentielCommuneDeNaissance
	from "../../composants/ChampReferentielCommuneDeNaissance/ChampReferentielCommuneDeNaissance"
import LieuDInscriptionValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/LieuDInscriptionValidation"
import ChampsPrenoms from "../../composants/ChampsPrenoms/ChampsPrenoms"
import ChampListeDeroulantePaysNationalite
	from "../../composants/ChampListeDeroulantePaysNationalite/ChampListeDeroulantePaysNationalite"
import PaysDeNaissanceValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/PaysDeNaissanceValidation"
import CommuneDeNaissanceValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/CommuneDeNaissanceValidation"
import ListeDElectionEuropeenneValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/ListeDElectionEuropeenneValidation"
import EtapeDemarche from "../../ui/EtapeDemarche/EtapeDemarche"
import AdresseEmailValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/AdresseEmailValidation"
import NumeroTelephoneValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/NumeroTelephoneValidation"
import { DepotDePiecesJustificativesContext, ValidateurDeDemarcheContext } from "../../App"
import ChampReferentielPaysDeNaissance
	from "../../composants/ChampReferentielPaysDeNaissance/ChampReferentielPaysDeNaissance"
import CommuneDeNaissanceAvecNumeroDepartementValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/CommuneDeNaissanceAvecNumeroDepartementValidation"
import TexteAideJustificatifDeDeclarationEcrite
	from "../../composants/PieceJustificative/TexteAideJustificatifDeDeclarationEcrite"
import TexteAideJustificatifIdentite from "../../composants/PieceJustificative/TexteAideJustificatifIdentite"
import TexteAideJustificatifDomicile from "../../composants/PieceJustificative/TexteAideJustificatifDomicile"
import { useUtilisateurCourant } from "../Authentifié"
import { DemarcheContexte } from "../Demarche/DemarcheContext"
import FormulaireAdresse from "../../composants/FormulaireAdresse/FormulaireAdresse"
import { useVerifierSiRnvpEstOK } from "./useVerifierSiRnvpEstOK"
import Cookies from "universal-cookie"
import ChoixTypeJustificatifValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/ChoixTypeJustificatifValidation"
import { ReponseUgle } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/types/UgleReponseHttp"
import {
	normaliserNomDeCommune,
	recupererNomCommuneEtCodePostalArrondissement
} from "../../../services/MoteurDeRechercheDeDonneesReferentiels/MoteurDeRechercheDeDonneesReferentielAxios"
import ChangementCommuneValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/ChangementCommuneValidation"
import PaysValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/PaysValidation"

import "./Formulaire.css"

const Formulaire: React.FunctionComponent = () => {
	const { valeursFormulaireEnregistrées, champAFocaliser, setChampAFocaliser, sauvegarderDonnéesValides } = useContext(DemarcheContexte)
	const { supprimer } = useContext(DepotDePiecesJustificativesContext)
	const utilisateurCourant = useUtilisateurCourant()
	const idUsager = utilisateurCourant.sub
	const email = utilisateurCourant.email
	const oidcLogin = utilisateurCourant.authenticationOrigin === "Oidc"
	const rnvpOK = useVerifierSiRnvpEstOK()
	const [valeursFormulaireDemarche, setValeursFormulaireDemarche] = useState<ChampsDuFormulaireDemarche>(valeursFormulaireEnregistrées)
	const [erreurFormulaireDemarche, setErreursFormulaireDemarche] = useState<Partial<ChampsDuFormulaireDemarche>>({
		sexe: "",
		nomDeFamille: "",
		pasDePrenom: false,
		prenom: "",
		prenom2: "",
		prenom3: "",
		nomDUsage: "",
		dateDeNaissance: "",
		dateDeNaissanceIncomplète: "",
		lieuDInscription: "",
		changementCommune: "",
		nationalite: "",
		typeDeListe: "",
		paysDeNaissance: "",
		communeDeNaissance: "",
		adresseDeRattachementUsagerRemplitSonAdresseManuellement: false,
		adresseDeRattachementPays: "",
		adresseDeRattachementAdresseRedressee: "",
		adresseDeRattachementCommune: "",
		adresseDeRattachementCodePostal: "",
		adresseDeRattachementNumero: "",
		adresseDeRattachementLibelleDeVoie: "",
		adresseDeRattachementLieuDit: "",
		adresseDeRattachementComplement1Adresse: "",
		adresseDeRattachementComplement2Adresse: "",
		adresseIdentiquePourProgrammeCandidats: "",
		adresseDeContactUsagerRemplitSonAdresseManuellement: false,
		adresseDeContactPays: "",
		adresseDeContactAdresseRedressee: "",
		adresseDeContactCommune: "",
		adresseDeContactCodePostal: "",
		adresseDeContactNumero: "",
		adresseDeContactLibelleDeVoie: "",
		adresseDeContactLieuDit: "",
		adresseDeContactComplement1Adresse: "",
		adresseDeContactComplement2Adresse: "",
		choixTypeJustificatif: "",
		idJustificatifIdentite: "",
		idJustificatifIdentite2: "",
		idJustificatifDomicile: "",
		idJustificatifDomicile2: "",
		idDeclarationEcrite: "",
		adresseEmail: "",
		numeroTelephone: "",
	})
	const [etatMessageDemarcheCouranteJustificatifIdentite, setMessageDemarcheCouranteJustificatifIdentite] = useState<string>("")
	const [etatMessageDemarcheCouranteJustificatifDomicile, setMessageDemarcheCouranteJustificatifDomicile] = useState<string>("")
	const [etatMessageDemarcheCouranteDeclarationEcrite, setMessageDemarcheCouranteDeclarationEcrite] = useState<string>("")
	const [viderChampAdresseRattachementRNVP, setViderChampAdresseRattachementRNVP] = useState<boolean>(false)
	const [nomDuChampEnErreurAFocaliser, setNomDuChampAFocaliser] = useState<string>()
	const { valider } = useContext(ValidateurDeDemarcheContext)
	const elementFormulaire = useRef<HTMLFormElement>(null)
	const fcBirthDateValidator = new DateDeNaissanceCompleteValidation()

	valeursFormulaireDemarche.adresseEmail == "" ? valeursFormulaireDemarche.adresseEmail = email:""

	useEffect(() => {
		document.title = "Formulaire de votre demande d’inscription sur les listes électorales - service-public.fr"
	}, [])

	useLayoutEffect(() => {
		if (nomDuChampEnErreurAFocaliser && elementFormulaire.current) {
			const elementAFocaliser: HTMLElement | null = elementFormulaire.current.querySelector(`[name=${nomDuChampEnErreurAFocaliser}]`) || elementFormulaire.current.querySelector(`#${nomDuChampEnErreurAFocaliser}`)
			if (elementAFocaliser) {
				elementAFocaliser.focus()
				setNomDuChampAFocaliser("")
			}
		}
	}, [nomDuChampEnErreurAFocaliser])

	useLayoutEffect(() => {
		if (champAFocaliser && elementFormulaire.current) {
			const elementAFocaliser: HTMLElement | null = elementFormulaire.current.querySelector(`[name=${champAFocaliser}]`) || elementFormulaire.current.querySelector(`#${champAFocaliser}`)
			if (elementAFocaliser) {
				elementAFocaliser.focus()
				setChampAFocaliser("")
			}
		}
	}, [champAFocaliser])

	const onChangeCommuneDeVote = (communeUgle: ReponseUgle) => {
		const nomCommuneEtCpArrondissementUgle = recupererNomCommuneEtCodePostalArrondissement(communeUgle.libelleCommune)
		const communeUgleNormalisee = normaliserNomDeCommune(nomCommuneEtCpArrondissementUgle[0])
		const cpUgle = nomCommuneEtCpArrondissementUgle[1] === "" ? valeursFormulaireDemarche.adresseDeRattachementCodePostal : nomCommuneEtCpArrondissementUgle[1]
		if ((valeursFormulaireDemarche.adresseDeRattachementCommune !== communeUgleNormalisee) || (valeursFormulaireDemarche.adresseDeRattachementCodePostal !== cpUgle)) {
			setViderChampAdresseRattachementRNVP(true)
			setValeursFormulaireDemarche({
				...valeursFormulaireDemarche,
				adresseDeRattachementCommune: "",
				adresseDeRattachementCodePostal: "",
				adresseDeRattachementNumero: "",
				adresseDeRattachementLibelleDeVoie: "",
				adresseDeRattachementLieuDit: "",
				adresseDeRattachementComplement1Adresse: "",
				adresseDeRattachementComplement2Adresse: "",
				adresseDeRattachementUsagerRemplitSonAdresseManuellement: false,
				adresseDeRattachementAdresseRedressee: "",
			})
		}
		setValeursFormulaireDemarche({
			...valeursFormulaireDemarche,
			lieuDInscription: communeUgle.libelleCommune,
			codeInseeLieuDInscription: communeUgle.codeCommune
		})
	}
	const mettreAJourLeChamp = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.getAttribute("name")) {
			const nomChamps = event.target.getAttribute("name") || ""
			const nouveauDemarcheValeursChamps = { ...valeursFormulaireDemarche, [nomChamps]: event.target.value }
			setValeursFormulaireDemarche(nouveauDemarcheValeursChamps)
		}
	}

	const mettreAJourLeChampEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.getAttribute("name")) {
			const nomChamps = event.target.getAttribute("name") || ""
			const nouveauDemarcheValeursChamps = { ...valeursFormulaireDemarche, [nomChamps]: event.target.value==""? undefined : event.target.value }
			setValeursFormulaireDemarche(nouveauDemarcheValeursChamps)
		}
	}

	const mettreAJourLeChampPaysNationalite = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.getAttribute("id") == "europeenne") {
			const nouveauDemarcheValeursChamps = { ...valeursFormulaireDemarche, nationalite: "" }
			setValeursFormulaireDemarche(nouveauDemarcheValeursChamps)
		} else {
			const nouveauDemarcheValeursChamps = { ...valeursFormulaireDemarche, nationalite: "FRA", typeDeListe: "Liste principale" }
			setValeursFormulaireDemarche(nouveauDemarcheValeursChamps)
		}
	}

	const mettreAJourLesValeursDuLieuDeNaissance = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.getAttribute("id") == "aLEtranger") {
			const nouveauDemarcheValeursChamps = { ...valeursFormulaireDemarche, paysDeNaissance: "", codeInseePaysDeNaissance: "", communeDeNaissance: "", codeInseeCommuneDeNaissance: "" }
			setValeursFormulaireDemarche(nouveauDemarcheValeursChamps)
		} else {
			const nouveauDemarcheValeursChamps = { ...valeursFormulaireDemarche, paysDeNaissance: "FRANCE", codeInseePaysDeNaissance: "99999", communeDeNaissance: "", codeInseeCommuneDeNaissance: "" }
			setValeursFormulaireDemarche(nouveauDemarcheValeursChamps)
		}
	}

	const [dateDeNaissanceEnMemoire, setDateDeNaissanceEnMemoire] = useState<string>("")
	const onChangeDateDeNaissance = (event: any) => {
		let valeur = event.target.value
		const longueur = valeur.length
		const longueurPrecedente = dateDeNaissanceEnMemoire.length
		if (longueurPrecedente < longueur) {
			if (longueur === 2 || longueur === 5) {
				valeur += "/"
			}
			valeur  = valeur.replace("//", "/")
			if (longueur === 2 && (valeur[1] === "/")) {
				valeur = "0" + valeur
			}
			if (longueur === 5 && (valeur[4] === "/")) {
				valeur = valeur.substring(0, 3) + "0" + valeur.substring(3)
 			}
		}
		setDateDeNaissanceEnMemoire(valeur)
		event.target.value = valeur
		mettreAJourLeChamp(event)
	}

	const mettreAJourLesValeurs = (champsAMettreAJour : Partial<ChampsDuFormulaireDemarche>) => {
		setValeursFormulaireDemarche({ ...valeursFormulaireDemarche, ...champsAMettreAJour })
	}

	const vérifierlesChampsDuFormulaire = async (event: SyntheticEvent) => {
		event.preventDefault()
		const cookies = new Cookies()
		const erreursDeValidations = valider(valeursFormulaireDemarche, oidcLogin ,rnvpOK)

		if (Object.entries(erreursDeValidations).length > 0) {
			setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, ...erreursDeValidations })
			const ordreAffichageDesChamps = [
				"nomDeFamille",
				"nomDUsage",
				"prenom",
				"prenom2",
				"prenom3",
				"dateDeNaissance",
				"dateDeNaissanceIncomplète",
				"paysDeNaissance",
				"communeDeNaissance",
				"sexe",
				"nationalite",
				"choixTypeJustificatif",
				"idJustificatifIdentite",
				"idJustificatifIdentite2",
				"lieuDInscription",
				"changementCommune",
				"typeDeListe",
				"adresseDeRattachementAdresseRedressee",
				"adresseDeRattachementCommune",
				"adresseDeRattachementCodePostal",
				"adresseDeRattachementNumero",
				"adresseDeRattachementLibelleDeVoie",
				"adresseDeRattachementLieuDit",
				"adresseDeRattachementComplement1Adresse",
				"adresseDeRattachementComplement2Adresse",
				"idJustificatifDomicile",
				"idJustificatifDomicile2",
				"adresseDeContactPays",
				"adresseDeContactAdresseRedressee",
				"adresseDeContactCommune",
				"adresseDeContactCodePostal",
				"adresseDeContactNumero",
				"adresseDeContactLibelleDeVoie",
				"adresseDeContactLieuDit",
				"adresseDeContactComplement1Adresse",
				"adresseDeContactComplement2Adresse",
				"idDeclarationEcrite",
				"adresseEmail",
				"numeroTelephone"]
			const nomDesChampsEnErreur = Object.keys(erreursDeValidations)
			const premierChampEnErreur = ordreAffichageDesChamps.find((nomChamps) => {
				return nomDesChampsEnErreur.includes(nomChamps)
			})
			setNomDuChampAFocaliser(premierChampEnErreur)
		} else {
			sauvegarderDonnéesValides(valeursFormulaireDemarche)
		}
		cookies.remove("numeroTeledossier")
	}

	const onDeleteIdentite1 = async () => {
		mettreAJourLesValeurs({ idJustificatifIdentite: "", nomJustificatifIdentite: "" })
		setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idJustificatifIdentite: "" })
		setMessageDemarcheCouranteJustificatifIdentite("")
	}

	const onDeleteIdentite2 = async () => {
		mettreAJourLesValeurs({ idJustificatifIdentite2: "", nomJustificatifIdentite2: "" })
		setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idJustificatifIdentite2: "" })
	}

	const onDeleteDomicile1 = async () => {
		mettreAJourLesValeurs({ idJustificatifDomicile: "", nomJustificatifDomicile: "" })
		setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idJustificatifDomicile: "" })
		setMessageDemarcheCouranteJustificatifDomicile("")
	}

	const onDeleteDomicile2 = async () => {
		mettreAJourLesValeurs({ idJustificatifDomicile2: "", nomJustificatifDomicile2: "" })
		setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idJustificatifDomicile2: "" })
		setMessageDemarcheCouranteJustificatifDomicile("")
	}

	const onDeleteDeclarationEcrite = async () => {
		mettreAJourLesValeurs({ idDeclarationEcrite: "", nomDeclarationEcrite: "" })
		setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idDeclarationEcrite: "" })
		setMessageDemarcheCouranteDeclarationEcrite("")
	}

	const getTitreFichierPieceJustificative = (typePieceJustificative: string, choixTypeJustificatif: string, position: string) => {
		if (typePieceJustificative === "identite") {
			if (choixTypeJustificatif === "identite") {
				if (position === "1") {
					return "Recto de votre carte"
				} else {
					return "Verso de votre carte"
				}
			} else if(choixTypeJustificatif === "passeport"){
				return ""
			} else {
				if (position === "1") {
					return "Fichier 1"
				} else {
					return "Fichier 2"
				}
			}
		}else if(typePieceJustificative === "domicile") {
			if (position === "1") {
				return "Fichier 1"
			} else {
				return "Fichier 2"
			}
		}
		return ""
	}

	const [texteAvertissementMajDateDeNaissance, setTexteAvertissementMajDateDeNaissance] = useState<string>(valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète ? "Vous devez maintenant saisir obligatoirement vos mois et année de naissance ou seulement votre année de naissance" : "Vous devez saisir obligatoirement vos jour, mois et année de naissance" )

	const auClickSurCaseACocherDateDeNaissance = (event: React.ChangeEvent<HTMLInputElement>) => {
		const laCaseEstCochée = event.target.checked
		setValeursFormulaireDemarche({
			...valeursFormulaireDemarche,
			dateDeNaissance: !laCaseEstCochée ? "" : undefined,
			dateDeNaissanceIncomplète: laCaseEstCochée ? "" : undefined,
			usagerAUneDateDeNaissanceIncomplète: laCaseEstCochée,
		})
		setTexteAvertissementMajDateDeNaissance(
			laCaseEstCochée ? "Vous devez maintenant saisir obligatoirement vos mois et année de naissance ou seulement votre année de naissance" : "Vous devez maintenant saisir obligatoirement vos jour, mois et année de naissance"
		)
	}
	const auClickSurCaseACocherUnSeulFichierPJ = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const laCaseEstCochée = event.target.checked

		if (laCaseEstCochée && valeursFormulaireDemarche.idJustificatifIdentite2 !== "") {
			await supprimer(idUsager, valeursFormulaireDemarche.idJustificatifIdentite2)
			mettreAJourLesValeurs({ idJustificatifIdentite2: "", nomJustificatifIdentite2: "" })
			setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idJustificatifIdentite2: "" })
		}
		setValeursFormulaireDemarche({
			...valeursFormulaireDemarche,
			idJustificatifIdentite2: !laCaseEstCochée ? "" : valeursFormulaireDemarche.idJustificatifIdentite2,
			nomJustificatifIdentite2: !laCaseEstCochée ? "" : valeursFormulaireDemarche.nomJustificatifIdentite2,
			unSeulFichierPJ: laCaseEstCochée,
		})
	}

	const auClickSurCaseACocherUnSeulFichierPJDomicile = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const laCaseEstCochée = event.target.checked

		if (laCaseEstCochée && valeursFormulaireDemarche.idJustificatifDomicile2 !== "") {
			await supprimer(idUsager, valeursFormulaireDemarche.idJustificatifDomicile2)
			mettreAJourLesValeurs({ idJustificatifDomicile2: "", nomJustificatifDomicile2: "" })
			setErreursFormulaireDemarche({ ...erreurFormulaireDemarche, idJustificatifDomicile2: "" })
		}
		setValeursFormulaireDemarche({
			...valeursFormulaireDemarche,
			idJustificatifDomicile2: !laCaseEstCochée ? "" : valeursFormulaireDemarche.idJustificatifDomicile2,
			nomJustificatifDomicile2: !laCaseEstCochée ? "" : valeursFormulaireDemarche.nomJustificatifDomicile2,
			unSeulFichierPJDomicile: laCaseEstCochée,
		})
	}

	const onClickSurAdresseDeContactEnFranceOuALEtranger = (event: React.ChangeEvent<HTMLInputElement>) => {
		const valeurRadio = event.target.value
		setValeursFormulaireDemarche( {
			...valeursFormulaireDemarche,
			adresseDeContactUsagerRemplitSonAdresseManuellement: false,
			adresseDeContactAdresseRedressee: "",
			adresseDeContactPays: valeurRadio === "En France"? "FRANCE" : "",
			adresseDeContactCommune: "",
			adresseDeContactCodePostal: "",
			adresseDeContactNumero: "",
			adresseDeContactLibelleDeVoie: "",
			adresseDeContactLieuDit: "",
			adresseDeContactComplement1Adresse: "",
			adresseDeContactComplement2Adresse: "",
		})
		setErreursFormulaireDemarche({
			...erreurFormulaireDemarche,
			adresseIdentiquePourProgrammeCandidats: "",
			adresseDeContactUsagerRemplitSonAdresseManuellement: false,
			adresseDeContactAdresseRedressee: "",
			adresseDeContactPays: "",
			adresseDeContactCommune: "",
			adresseDeContactCodePostal: "",
			adresseDeContactNumero: "",
			adresseDeContactLibelleDeVoie: "",
			adresseDeContactLieuDit: "",
			adresseDeContactComplement1Adresse: "",
			adresseDeContactComplement2Adresse: "",
		})
	}

	const auClickSurRadioAdresseDifferentePourProgrammeCandidats = (event: React.ChangeEvent<HTMLInputElement>) => {
		const valeurRadio = event.target.value
		setValeursFormulaireDemarche( {
			...valeursFormulaireDemarche,
			adresseIdentiquePourProgrammeCandidats: valeurRadio,
			adresseDeContactUsagerRemplitSonAdresseManuellement: false,
			adresseDeContactAdresseRedressee: "",
			adresseDeContactCommune: "",
			adresseDeContactCodePostal: "",
			adresseDeContactNumero: "",
			adresseDeContactLibelleDeVoie: "",
			adresseDeContactLieuDit: "",
			adresseDeContactComplement1Adresse: "",
			adresseDeContactComplement2Adresse: "",
		})
		setErreursFormulaireDemarche({
			...erreurFormulaireDemarche,
			adresseIdentiquePourProgrammeCandidats: "",
			adresseDeContactUsagerRemplitSonAdresseManuellement: false,
			adresseDeContactAdresseRedressee: "",
			adresseDeContactCommune: "",
			adresseDeContactCodePostal: "",
			adresseDeContactNumero: "",
			adresseDeContactLibelleDeVoie: "",
			adresseDeContactLieuDit: "",
			adresseDeContactComplement1Adresse: "",
			adresseDeContactComplement2Adresse: "",
		})
	}

	const messageAideALaSaisiePieceJustificativeIdentite1=
		<Fragment>
			<p id="idMessageAideALaSaisie1">
				<strong>Choisissez</strong> un ou plusieurs <strong>documents</strong> prouvant <strong>votre identité</strong> et <strong>nationalité</strong>.
			</p>
		</Fragment>

	const messageAideALaSaisiePieceJustificativeIdentite2 =
		<Fragment>
			<p id="idMessageAideALaSaisie2">
				Pensez à <strong>vérifier</strong> que votre titre d’identité est <strong>valide</strong>.
				{ valeursFormulaireDemarche.choixTypeJustificatif !== "passeport" &&
					<Fragment>
						&nbsp;Pour une <strong>carte</strong>, reproduisez le <strong>recto et le verso</strong>.
					</Fragment>
				}
			</p>
		</Fragment>

	return (
		<form
			className="formulaire"
			noValidate
			onSubmit={ vérifierlesChampsDuFormulaire }
			ref={ elementFormulaire }
		>
			<EtapeDemarche
				id="etape-1"
				numeroEtape="1"
				titre="Votre identité"
			/>

			<MiseEnAvant
				couleur={ "bleu" }
				icone={ "info" }
			>
				Sauf indication contraire, <strong>toutes les informations demandées sont obligatoires</strong> pour traiter votre
				demande.
			</MiseEnAvant>

			{ oidcLogin && 
			<>
				<div className="sp-demarche-step-recap">
					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="fr-icon-account-line">
						</span>
					</p>
					<p>Vous avez utilisé FranceConnect pour vous authentifier, les informations suivantes seront transmises à votre mairie
					</p>
					<div>
						<div 
							id="nomDeFamille"
							tabIndex={ -1 }>Nom: <strong>{ valeursFormulaireDemarche.nomDeFamille }</strong>
						</div>
						<div>Nom d&apos;usage: <strong>{ valeursFormulaireDemarche.nomDUsage }</strong></div>
						<div>Prénom(s): <strong>{ valeursFormulaireDemarche.prenom }</strong></div>
						<div>Sexe: <strong>{ valeursFormulaireDemarche.sexe }</strong></div>
						<div 
							id="dateDeNaissance"  
							tabIndex={ -1 }>Date de naissance: <strong>{ valeursFormulaireDemarche.dateDeNaissance }</strong>
						</div>
						<div>Pays de naissance: <strong>{ valeursFormulaireDemarche.paysDeNaissance }</strong></div>
						{ valeursFormulaireDemarche.paysDeNaissance === "FRANCE" &&
						<div>Commune de naissance: <strong>{ valeursFormulaireDemarche.communeDeNaissance }</strong></div>
						}
					</div>
				</div>
				{ !fcBirthDateValidator.estValide(`${valeursFormulaireDemarche.dateDeNaissance}`) && 
				<p
					className="fr-error-text"
					role="alert"
				>
					{ fcBirthDateValidator.messageErreur(`${valeursFormulaireDemarche.dateDeNaissance}`) }
				</p> }
				{ valeursFormulaireDemarche.dateDeNaissance?.startsWith("01") && 
				<CaseACocher
					caseEstCochée={ valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète }
					id="no-birthday"
					label="Mon acte de naissance n’indique pas la date complète"
					onChange={ (event) => setValeursFormulaireDemarche({
						...valeursFormulaireDemarche,
						usagerAUneDateDeNaissanceIncomplète: event.target.checked,
					}) }
					texteAide={ valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète ? "Décochez si vous n’êtes pas concerné" : "Cochez si vous êtes concerné" }
					valeur="no-birthday"
				/> }
			</>
			}

			{ !oidcLogin && 
			<>
			    <h3 className="fr-text--lg fr-mb-2w fr-mt-6w">Quel est votre nom ?</h3>
			    <ChampTextuel
				    autoComplete="family-name"
				    id={ "nomDeFamille" }
				    label="Nom"
				    messageErreurFormulaire={ erreurFormulaireDemarche.nomDeFamille }
				    name={ "nomDeFamille" }
				    onChange={ mettreAJourLeChamp }
				    required={ true }
				    texteAide="Nom de naissance ou nom modifié par procédure légale"
				    valeur={ valeursFormulaireDemarche.nomDeFamille }
				    validation={ new NomDeFamilleValidation() }
			    />

				<ChampTextuel
					id={ "nomDUsage" }
					label="Nom d'usage"
					messageErreurFormulaire={ erreurFormulaireDemarche.nomDUsage }
					name={ "nomDUsage" }
					onChange={ mettreAJourLeChamp }
					texteAide="Si vous avez un nom d’usage, par exemple un nom d’épouse ou d’époux, indiquez-le"
					valeur={ valeursFormulaireDemarche.nomDUsage }
					validation={ new NomDUsageValidation() }
				/>
				<ChampsPrenoms
					erreursPrenomsFormulaireDemarche={ {
						pasDePrenom: false,
						prenom: erreurFormulaireDemarche.prenom,
						prenom2: erreurFormulaireDemarche.prenom2,
						prenom3: erreurFormulaireDemarche.prenom3
					} }
					mettreAJourLesValeursDesPrenoms={ mettreAJourLesValeurs }
					valeursPrenomsInitiales={ {
						pasDePrenom: valeursFormulaireDemarche.pasDePrenom,
						prenom: valeursFormulaireDemarche.prenom,
						prenom2: valeursFormulaireDemarche.prenom2,
						prenom3: valeursFormulaireDemarche.prenom3
					} }
				/>

				<h3 className="fr-text--lg fr-mb-2w fr-mt-6w">Quelle est votre date de naissance ?</h3>
				<CaseACocher
					caseEstCochée={ valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète }
					id="no-birthday"
					label="Mon acte de naissance n’indique pas la date complète"
					onChange={ auClickSurCaseACocherDateDeNaissance }
					texteAide={ valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète ? "Décochez si vous n’êtes pas concerné" : "Cochez si vous êtes concerné" }
					texteAvertissementDeLaMiseAJour= { texteAvertissementMajDateDeNaissance }
					valeur="no-birthday"
				/>
				{ valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète &&
					<Fragment>
						<p id="aide-description-date-de-naissance-incomplete">Indiquez l’année et, si possible, le mois comme sur votre acte de naissance</p>
						<ChampTextuel
							id="dateDeNaissanceIncomplète"
							label="Année et mois de naissance"
							largeur="petit"
							messageErreurFormulaire={ erreurFormulaireDemarche.dateDeNaissanceIncomplète }
							name="dateDeNaissanceIncomplète"
							onChange={ mettreAJourLeChamp }
							required={ true }
							texteAide="Écrivez la date avec le format MM/AAAA ou AAAA. Par exemple : 06/1986 ou 1986"
							valeur={ valeursFormulaireDemarche.dateDeNaissanceIncomplète }
							validation={ new DateDeNaissanceIncompleteValidation() }
						/>
					</Fragment>
				}
				{ !valeursFormulaireDemarche.usagerAUneDateDeNaissanceIncomplète &&
				<ChampTextuel
					autoComplete="bday"
					id={ "dateDeNaissance" }
					label={ "Date de naissance" }
					largeur="petit"
					messageErreurFormulaire={ erreurFormulaireDemarche.dateDeNaissance }
					name={ "dateDeNaissance" }
					onChange={ onChangeDateDeNaissance }
					required={ true }
					texteAide="Exemple : 21/06/1986"
					valeur={ valeursFormulaireDemarche.dateDeNaissance }
					validation={ new DateDeNaissanceCompleteValidation() }
				/>
				}
				<ChampBoutonsRadios
					boutonsRadio={ [{
						valeur: "En France",
						id: "enFrance",
						idMessageErreur: "",
						label: "En France",
						nom: "EnFranceOuALEtranger",
						onChange: mettreAJourLesValeursDuLieuDeNaissance
					}, {
						valeur: "À l’étranger",
						id: "aLEtranger",
						idMessageErreur: "",
						label: "À l’étranger",
						nom: "EnFranceOuALEtranger",
						messageChangementDeContexte: "Saisie complémentaire requise - affichage automatique",
						onChange: mettreAJourLesValeursDuLieuDeNaissance
					}] }
					legende="Quel est votre lieu de naissance ?"
					valeurCochee={ valeursFormulaireDemarche.paysDeNaissance === "FRANCE" ? "En France" : "À l’étranger" }
				/>

				{ valeursFormulaireDemarche.paysDeNaissance === "FRANCE" && (
					<ChampReferentielCommuneDeNaissance
						codeInseeCommuneDeNaissance={ valeursFormulaireDemarche.codeInseeCommuneDeNaissance }
						communeDeNaissance={ valeursFormulaireDemarche.communeDeNaissance }
						messageErreurFormulaire={ erreurFormulaireDemarche.communeDeNaissance }
						onChange={ (lieuDeNaissance) => setValeursFormulaireDemarche( {
							...valeursFormulaireDemarche,
							communeDeNaissance: lieuDeNaissance.libelle,
							codeInseeCommuneDeNaissance: lieuDeNaissance.code
						}) }
						validation={ new CommuneDeNaissanceAvecNumeroDepartementValidation() }
					/>
				) }
			</>
			}
			{ valeursFormulaireDemarche.paysDeNaissance !== "FRANCE" && (
				<Fragment>
					{ !oidcLogin &&
					<>
						<ChampReferentielPaysDeNaissance
							messageErreurFormulaire={ erreurFormulaireDemarche.paysDeNaissance }
							onChange={ (pays) => {
								if (pays.libelle === "FRANCE")
									setValeursFormulaireDemarche({
										...valeursFormulaireDemarche,
										paysDeNaissance: pays.libelle,
										codeInseePaysDeNaissance: pays.code,
										communeDeNaissance: ""
									})
								else
									setValeursFormulaireDemarche({
										...valeursFormulaireDemarche,
										paysDeNaissance: pays.libelle,
										codeInseePaysDeNaissance: pays.code
									})
							} }
							paysDeNaissance={ valeursFormulaireDemarche.paysDeNaissance }
							validation={ new PaysDeNaissanceValidation() }
						/>
					</>
					}
					<ChampTextuel
						id="communeDeNaissance"
						label="Commune de naissance"
						maxLength={ 50 }
						messageErreurFormulaire={ erreurFormulaireDemarche.communeDeNaissance }
						name="communeDeNaissance"
						onChange={ mettreAJourLeChamp }
						required={ true }
						texteAide="Exemple : Munich"
						valeur={ valeursFormulaireDemarche.communeDeNaissance }
						validation={ new CommuneDeNaissanceValidation() }
					/>
				</Fragment>
			) }

			{ !oidcLogin && 
			<>
				<ChampBoutonsRadiosAvecValidation
					afficherBoutonsRadiosEnLigne ={ true }
					boutonsRadio={ [{
						valeur: "Féminin",
						idMessageErreur: "sexe-champs-boutons-radios-error-message",
						id: "feminin",
						label: "Féminin",
						nom: "sexe",
						required: true,
						onChange: mettreAJourLeChamp
					}, {
						valeur: "Masculin",
						id: "masculin",
						idMessageErreur: "sexe-champs-boutons-radios-error-message",
						label: "Masculin",
						nom: "sexe",
						required: true,
						onChange: mettreAJourLeChamp
					}] }
					idMessageErreur= "sexe-champs-boutons-radios-error-message"
					legende="Quel est votre sexe ?"
					messageErreur={ erreurFormulaireDemarche.sexe }
					required={ true }
					valeurCochee={ valeursFormulaireDemarche.sexe }
					validation={ new SexeValidation() }
				/>
			</>
			}

			<ChampBoutonsRadios
				boutonsRadio={ [{
					valeur: "Française",
					id: "francaise",
					idMessageErreur: "",
					label: "Française",
					nom: "francaiseOuEuropeenne",
					onChange: mettreAJourLeChampPaysNationalite
				}, {
					valeur: "Européenne",
					id: "europeenne",
					idMessageErreur: "",
					label: "Autre pays de l'Union européenne",
					nom: "francaiseOuEuropeenne",
					messageChangementDeContexte: "Saisie complémentaire requise - affichage automatique",
					onChange: mettreAJourLeChampPaysNationalite
				}] }
				legende="Quelle est votre nationalité ?"
				valeurCochee={ valeursFormulaireDemarche.nationalite === "FRA" ? "Française" : "Européenne" }
			/>

			{ valeursFormulaireDemarche.nationalite !== "FRA" &&
				<ChampListeDeroulantePaysNationalite
					messageErreurFormulaire={ erreurFormulaireDemarche.nationalite }
					mettreAJourLaValeurDuPaysDeNationalite={ mettreAJourLesValeurs }
					valeurSelectionnee={ valeursFormulaireDemarche.nationalite }
					validation={ new NationaliteValidation() }
				/>

			}
			<span>{ etatMessageDemarcheCouranteJustificatifIdentite }</span>
			<div className="fr-mb-2w">
				<BlocPiecesJustificatives
					auClickSurCaseACocherUnSeulFichierPJ={ auClickSurCaseACocherUnSeulFichierPJ }
					choixTypeJustificatif={ valeursFormulaireDemarche.choixTypeJustificatif }
					libelleLienExterne="Lire sur service-public.fr"
					lienExterne={ "https://www.service-public.fr/particuliers/vosdroits/F34779" }
					listePiecesJustificatives=
						{ [
							{
								cacher: false,
								idPieceJustificative: valeursFormulaireDemarche.idJustificatifIdentite,
								idUsager: idUsager,
								label: "Ajouter un fichier ou une photo",
								messageErreurFormulaire: erreurFormulaireDemarche.idJustificatifIdentite,
								name: "idJustificatifIdentite",
								nomPieceJustificative: valeursFormulaireDemarche.nomJustificatifIdentite,
								onChange: (idPieceJustificative, nomPieceJustificative) => {
									setValeursFormulaireDemarche({
										...valeursFormulaireDemarche,
										idJustificatifIdentite: idPieceJustificative,
										nomJustificatifIdentite: nomPieceJustificative
									})
								},
								onDelete: onDeleteIdentite1,
								position: "1",
								required: true,
								titre: getTitreFichierPieceJustificative("identite", valeursFormulaireDemarche.choixTypeJustificatif, "1"),
								typePieceJustificative: "identite"
							},
							{
								cacher: (valeursFormulaireDemarche.unSeulFichierPJ || (valeursFormulaireDemarche.choixTypeJustificatif === "passeport")),
								idPieceJustificative: valeursFormulaireDemarche.idJustificatifIdentite2,
								idUsager: idUsager,
								label: "Ajouter un fichier ou une photo",
								messageErreurFormulaire: erreurFormulaireDemarche.idJustificatifIdentite2,
								name: "idJustificatifIdentite2",
								nomPieceJustificative: valeursFormulaireDemarche.nomJustificatifIdentite2,
								onChange: (idPieceJustificative2, nomPieceJustificative2) => {
									setValeursFormulaireDemarche({
										...valeursFormulaireDemarche,
										idJustificatifIdentite2: idPieceJustificative2,
										nomJustificatifIdentite2: nomPieceJustificative2
									})
								},
								onDelete: onDeleteIdentite2,
								position: "2",
								required: true,
								titre: getTitreFichierPieceJustificative("identite", valeursFormulaireDemarche.choixTypeJustificatif, "2"),
								typePieceJustificative: "identite"
							},
						]
						}
					messageAideALaSaisie1={ messageAideALaSaisiePieceJustificativeIdentite1 }
					messageAideALaSaisie2={ messageAideALaSaisiePieceJustificativeIdentite2 }
					messageErreurFormulaire={ erreurFormulaireDemarche.choixTypeJustificatif }
					mettreAJourLaValeurDuChoixDeTypeJustificatif={ mettreAJourLesValeurs }
					required={ true }
					texteAidePJ={
						<TexteAideJustificatifIdentite/>
					}
					titreBloc="Vos justificatifs d'identité"
					typePieceJustificative="identite"
					unSeulFichierPJ={ valeursFormulaireDemarche.unSeulFichierPJ }
					validation={ new ChoixTypeJustificatifValidation() }
				/>
			</div>

			<EtapeDemarche
				id="etape-2"
				numeroEtape="2"
				titre="Votre souhait d’inscription"
			/>

			<h3 className="fr-text--lg fr-mb-2w fr-mt-6w">{ "Dans quelle commune souhaitez-vous voter ?" }</h3>
			<ChampReferentielLieuDInscription
				codeInseeLieuDInscription={ valeursFormulaireDemarche.codeInseeLieuDInscription }
				lieuDInscription={ valeursFormulaireDemarche.lieuDInscription }
				messageErreurFormulaire={ erreurFormulaireDemarche.lieuDInscription }
				onChange={ (communeUgle) => onChangeCommuneDeVote(communeUgle) }
				validation={ new LieuDInscriptionValidation() }
			/>

			<ChampBoutonsRadiosAvecValidation
				afficherBoutonsRadiosEnLigne ={ true }
				boutonsRadio={ [{
					valeur: "non",
					id: "changementCommuneNon",
					idMessageErreur: "changementCommune-champs-boutons-radios-error-message",
					label: "Oui",
					nom: "changementCommune",
					required: true,
					onChange: mettreAJourLeChamp
				},{
					valeur: "oui",
					idMessageErreur: "changementCommune-champs-boutons-radios-error-message",
					id: "changementCommuneOui",
					label: "Non",
					nom: "changementCommune",
					required: true,
					onChange: mettreAJourLeChamp
				}] }
				idMessageErreur= "changementCommune-champs-boutons-radios-error-message"
				legende="Êtes-vous actuellement inscrit(e) dans cette commune ou cet arrondissement ?"
				messageErreur={ erreurFormulaireDemarche.changementCommune }
				required={ true }
				valeurCochee={ valeursFormulaireDemarche.changementCommune }
				validation={ new ChangementCommuneValidation() }
			/>

			{ valeursFormulaireDemarche.nationalite !== "FRA" &&
			<ChampBoutonsRadiosAvecValidation
				afficherBoutonsRadiosEnLigne ={ false }
				boutonsRadio={ [{
					valeur: "Élections européennes",
					id: "electioneuropeenne",
					idMessageErreur: "typeDeListe-champs-boutons-radios-error-message",
					label: "Élections européennes",
					nom: "typeDeListe",
					required: true,
					onChange: mettreAJourLeChamp,
				}, {
					valeur: "Élections municipales",
					id: "electionmunicipale",
					idMessageErreur: "typeDeListe-champs-boutons-radios-error-message",
					label: "Élections municipales",
					nom: "typeDeListe",
					required: true,
					onChange: mettreAJourLeChamp
				}, {
					valeur: "Élections européennes et municipales",
					id: "electioneuropeenneetmunicipale",
					idMessageErreur: "typeDeListe-champs-boutons-radios-error-message",
					label: "Élections européennes et municipales",
					nom: "typeDeListe",
					required: true,
					onChange: mettreAJourLeChamp
				}] }
				idMessageErreur="typeDeListe-champs-boutons-radios-error-message"
				legende="Pour quelles élections souhaitez-vous voter ?"
				messageErreur={ erreurFormulaireDemarche.typeDeListe }
				required={ true }
				valeurCochee={ valeursFormulaireDemarche.typeDeListe }
				validation={ new ListeDElectionEuropeenneValidation() }
			/>
			}
			<h3 className="fr-text--lg fr-mb-2w fr-mt-6w">Quelle est votre adresse dans la commune ?</h3>
			<FormulaireAdresse
				adresse={ {
					usagerRemplitSonAdresseManuellement: valeursFormulaireDemarche.adresseDeRattachementUsagerRemplitSonAdresseManuellement,
					adresseRedressee: valeursFormulaireDemarche.adresseDeRattachementAdresseRedressee,
					pays: valeursFormulaireDemarche.adresseDeRattachementPays,
					commune: valeursFormulaireDemarche.adresseDeRattachementCommune,
					codePostal: valeursFormulaireDemarche.adresseDeRattachementCodePostal,
					numero: valeursFormulaireDemarche.adresseDeRattachementNumero,
					libelleDeVoie: valeursFormulaireDemarche.adresseDeRattachementLibelleDeVoie,
					lieuDit: valeursFormulaireDemarche.adresseDeRattachementLieuDit,
					complement1Adresse: valeursFormulaireDemarche.adresseDeRattachementComplement1Adresse,
					complement2Adresse: valeursFormulaireDemarche.adresseDeRattachementComplement2Adresse,
					autocompleteAdresse : valeursFormulaireDemarche.adresseIdentiquePourProgrammeCandidats=="Non" ? false : true
				} }
				afficherRNVP={ rnvpOK }
				filtreCommune={ valeursFormulaireDemarche.lieuDInscription }
				filtreNumeroDepartement={ valeursFormulaireDemarche.codeInseeLieuDInscription }
				messageErreurFormulaire={ {
					adresseRedressee: erreurFormulaireDemarche.adresseDeRattachementAdresseRedressee ? erreurFormulaireDemarche.adresseDeRattachementAdresseRedressee : "" ,
					codePostal: erreurFormulaireDemarche.adresseDeRattachementCodePostal ? erreurFormulaireDemarche.adresseDeRattachementCodePostal : "",
					commune: erreurFormulaireDemarche.adresseDeRattachementCommune ? erreurFormulaireDemarche.adresseDeRattachementCommune : "",
					complement1Adresse: erreurFormulaireDemarche.adresseDeRattachementComplement1Adresse ? erreurFormulaireDemarche.adresseDeRattachementComplement1Adresse : "",
					complement2Adresse: erreurFormulaireDemarche.adresseDeRattachementComplement2Adresse ? erreurFormulaireDemarche.adresseDeRattachementComplement2Adresse : "",
					libelleDeVoie: erreurFormulaireDemarche.adresseDeRattachementLibelleDeVoie ? erreurFormulaireDemarche.adresseDeRattachementLibelleDeVoie : "",
					lieuDit: erreurFormulaireDemarche.adresseDeRattachementLieuDit ? erreurFormulaireDemarche.adresseDeRattachementLieuDit : "",
					numero: erreurFormulaireDemarche.adresseDeRattachementNumero ? erreurFormulaireDemarche.adresseDeRattachementNumero : "",
				} }
				mettreAJourLeChamp={ mettreAJourLeChamp }
				setErreursValeursAdresse={ (erreurs) => {
					setErreursFormulaireDemarche( {
						...erreurFormulaireDemarche,
						adresseDeRattachementLibelleDeVoie: erreurs.libelleDeVoie,
						adresseDeRattachementLieuDit: erreurs.lieuDit
					})
				} }
				setValeursAdresse={
					(adresse) => {
						setValeursFormulaireDemarche({
							...valeursFormulaireDemarche,
							adresseDeRattachementUsagerRemplitSonAdresseManuellement: adresse.usagerRemplitSonAdresseManuellement,
							adresseDeRattachementAdresseRedressee: adresse.adresseRedressee,
							adresseDeRattachementCommune: adresse.commune,
							adresseDeRattachementCodePostal: adresse.codePostal,
							adresseDeRattachementNumero: adresse.numero,
							adresseDeRattachementLibelleDeVoie: adresse.libelleDeVoie,
							adresseDeRattachementLieuDit: adresse.lieuDit,
							adresseDeRattachementComplement1Adresse: adresse.complement1Adresse,
							adresseDeRattachementComplement2Adresse: adresse.complement2Adresse
						})
						setViderChampAdresseRattachementRNVP(false)
					}
				}
				typeAdresse="adresseDeRattachement"
				viderChamp={ viderChampAdresseRattachementRNVP }
			/>
			<span>{ etatMessageDemarcheCouranteJustificatifDomicile }</span>
			<div className="fr-mb-4w">
				<BlocPiecesJustificatives
					auClickSurCaseACocherUnSeulFichierPJ={ auClickSurCaseACocherUnSeulFichierPJDomicile }
					choixTypeJustificatif={ "" }
					libelleLienExterne="Lire sur service-public.fr"
					lienExterne={ "https://www.service-public.fr/particuliers/vosdroits/F1965" }
					listePiecesJustificatives={ [
						{
							cacher: false,
							idPieceJustificative: valeursFormulaireDemarche.idJustificatifDomicile,
							idUsager: idUsager,
							label: "Ajouter un fichier ou une photo",
							messageErreurFormulaire: erreurFormulaireDemarche.idJustificatifDomicile,
							name: "idJustificatifDomicile",
							nomPieceJustificative: valeursFormulaireDemarche.nomJustificatifDomicile,
							onChange: (idPieceJustificative, nomPieceJustificative) => {
								setValeursFormulaireDemarche({
									...valeursFormulaireDemarche,
									idJustificatifDomicile: idPieceJustificative,
									nomJustificatifDomicile: nomPieceJustificative
								})
							},
							onDelete: onDeleteDomicile1,
							position: "1",
							required: true,
							titre: getTitreFichierPieceJustificative("domicile", "", "1"),
							typePieceJustificative: "domicile"
						},
						{
							cacher: valeursFormulaireDemarche.unSeulFichierPJDomicile,
							idPieceJustificative: valeursFormulaireDemarche.idJustificatifDomicile2,
							idUsager: idUsager,
							label: "Ajouter un fichier ou une photo",
							messageErreurFormulaire: erreurFormulaireDemarche.idJustificatifDomicile2,
							name: "idJustificatifDomicile2",
							nomPieceJustificative: valeursFormulaireDemarche.nomJustificatifDomicile2,
							onChange: (idPieceJustificative, nomPieceJustificative) => {
								setValeursFormulaireDemarche({
									...valeursFormulaireDemarche,
									idJustificatifDomicile2: idPieceJustificative,
									nomJustificatifDomicile2: nomPieceJustificative
								})
							},
							onDelete: onDeleteDomicile2,
							position: "2",
							required: true,
							titre: getTitreFichierPieceJustificative("domicile", "", "2"),
							typePieceJustificative: "domicile"
						}
					] }
					messageAideALaSaisie1={
						<Fragment>
							<p>
								<strong>Choisissez</strong> votre ou vos <strong>documents</strong> prouvant <strong>votre lien avec la commune</strong>. Par exemple une facture d’électricité pour prouver votre domicile.
							</p>
							<p>
								Les <strong>factures de téléphone mobile</strong> ne sont <strong>pas acceptées</strong>. Pensez à <strong>vérifier</strong> que vos <strong>factures</strong> datent de <strong>moins de 3 mois</strong>.
							</p>
						</Fragment>
					}
					mettreAJourLaValeurDuChoixDeTypeJustificatif={ mettreAJourLesValeurs }
					required={ true }
					texteAidePJ={
						<TexteAideJustificatifDomicile/>
					}
					titreBloc="Justificatifs de domicile ou de lien avec la commune"
					typePieceJustificative="domicile"
					unSeulFichierPJ={ valeursFormulaireDemarche.unSeulFichierPJDomicile }
					validation={ new ChoixTypeJustificatifValidation() }
				/>
			</div>
			<span>{ etatMessageDemarcheCouranteDeclarationEcrite }</span>
			{ valeursFormulaireDemarche.nationalite !== "FRA" && (
				<div className="fr-mb-4w">
					<BlocPiecesJustificatives
						auClickSurCaseACocherUnSeulFichierPJ={ auClickSurCaseACocherUnSeulFichierPJ }
						choixTypeJustificatif={ "" }
						libelleLienExterne="Rédiger et télécharger votre déclaration sur service-public.fr"
						lienExterne="https://www.service-public.fr/simulateur/calcul/DeclarationInscriptionEuropeen"
						listePiecesJustificatives={ [
							{
								cacher: false,
								idPieceJustificative: valeursFormulaireDemarche.idDeclarationEcrite,
								idUsager: idUsager,
								label: "Ajouter un fichier ou une photo",
								messageErreurFormulaire: erreurFormulaireDemarche.idDeclarationEcrite,
								name: "idDeclarationEcrite",
								nomPieceJustificative: valeursFormulaireDemarche.nomDeclarationEcrite,
								onChange: (idPieceJustificative, nomPieceJustificative) => {
									setValeursFormulaireDemarche({
										...valeursFormulaireDemarche,
										idDeclarationEcrite: idPieceJustificative,
										nomDeclarationEcrite: nomPieceJustificative
									})
								},
								onDelete: onDeleteDeclarationEcrite,
								position: "1",
								required: true,
								titre: getTitreFichierPieceJustificative("declarationEcrite", "", "1"),
								typePieceJustificative: "declarationEcrite"
							}
						] }
						messageAideALaSaisie1=
							{
								<Fragment>
									<p>
										<strong>Rédigez</strong> votre&nbsp;
										<a
											href="https://www.service-public.fr/simulateur/calcul/DeclarationInscriptionEuropeen"
											rel="noopener noreferrer"
											target="_blank"
											title="Rédigez votre déclaration sur l’honneur attestant de votre situation électorale - nouvelle fenêtre">
											<strong>déclaration sur l’honneur</strong>
										</a> attestant de <strong>votre situation électorale</strong>.
									</p>
									<p>
										<strong>Réunissez</strong> vos documents dans <strong>un seul fichier</strong>.
									</p>
								</Fragment>
							}
						mettreAJourLaValeurDuChoixDeTypeJustificatif={ mettreAJourLesValeurs }
						required={ true }
						texteAidePJ={ <TexteAideJustificatifDeDeclarationEcrite/> }
						titreBloc="Déclaration pour les citoyens d'autres pays de l'Union européenne"
						typePieceJustificative="declarationEcrite"
						unSeulFichierPJ
					/>
				</div>
			) }

			<EtapeDemarche
				id="etape-3"
				numeroEtape="3"
				titre="Vos coordonnées"
			/>

			<h3 className="fr-text--lg fr-mb-2w fr-mt-6w">&Agrave; quelle adresse souhaitez-vous recevoir les programmes des candidats ?</h3>
			<ChampBoutonsRadios
				boutonsRadio={ [{
					valeur: "Oui",
					id: "adresseIdentiquePourProgrammeCandidats",
					idMessageErreur: "adresseIdentiquePourProgrammeCandidats-champs-boutons-radios-error-message",
					label: "Oui",
					nom: "adresseIdentiquePourProgrammeCandidats",
					required: true,
					onChange: auClickSurRadioAdresseDifferentePourProgrammeCandidats
				}, {
					valeur: "Non",
					id: "adresseDifferentePourProgrammeCandidats",
					idMessageErreur: "adresseIdentiquePourProgrammeCandidats-champs-boutons-radios-error-message",
					label: "Non",
					messageChangementDeContexte: "Saisie complémentaire requise - affichage automatique",
					nom: "adresseIdentiquePourProgrammeCandidats",
					required: true,
					onChange: auClickSurRadioAdresseDifferentePourProgrammeCandidats
				}] }
				labelEstTitreDeSection={ false }
				legende="&Agrave; votre adresse dans la commune ?"
				valeurCochee={ valeursFormulaireDemarche.adresseIdentiquePourProgrammeCandidats === "Non" ? "Non" : "Oui" }
			/>
			{ valeursFormulaireDemarche.adresseIdentiquePourProgrammeCandidats=="Non" &&
				<Fragment>
					<ChampBoutonsRadios
						boutonsRadio={ [{
							valeur: "En France",
							id: "adresseDeContactEnFrance",
							label: "En France",
							nom: "adresseDeContactEnFranceOuALEtranger",
							required: true,
							onChange: onClickSurAdresseDeContactEnFranceOuALEtranger
						}, {
							valeur: "À l’étranger",
							id: "adresseDeContactALEtranger",
							label: "À l’étranger",
							nom: "adresseDeContactEnFranceOuALEtranger",
							messageChangementDeContexte: "Saisie complémentaire requise - affichage automatique",
							required: true,
							onChange: onClickSurAdresseDeContactEnFranceOuALEtranger
						}] }
						labelEstTitreDeSection={ false }
						legende="En France ou à l’étranger ?"
						valeurCochee={ valeursFormulaireDemarche.adresseDeContactPays === "FRANCE" ? "En France" : "À l’étranger" }
					/>

					{ valeursFormulaireDemarche.adresseDeContactPays !== "FRANCE" &&
					<ChampTextuel
						id="adresseDeContactPays"
						label="Pays"
						maxLength={ 50 }
						messageErreurFormulaire={ erreurFormulaireDemarche.adresseDeContactPays }
						name="adresseDeContactPays"
						onChange={ mettreAJourLeChamp }
						required={ true }
						texteAide="Exemple : Estonie"
						valeur={ valeursFormulaireDemarche.adresseDeContactPays }
						validation={ new PaysValidation() }
					/>
					}
					<FormulaireAdresse
						adresse={ {
							usagerRemplitSonAdresseManuellement: valeursFormulaireDemarche.adresseDeContactUsagerRemplitSonAdresseManuellement,
							adresseRedressee: valeursFormulaireDemarche.adresseDeContactAdresseRedressee,
							pays: valeursFormulaireDemarche.adresseDeContactPays,
							commune: valeursFormulaireDemarche.adresseDeContactCommune,
							codePostal: valeursFormulaireDemarche.adresseDeContactCodePostal,
							numero: valeursFormulaireDemarche.adresseDeContactNumero,
							libelleDeVoie: valeursFormulaireDemarche.adresseDeContactLibelleDeVoie,
							lieuDit: valeursFormulaireDemarche.adresseDeContactLieuDit,
							complement1Adresse: valeursFormulaireDemarche.adresseDeContactComplement1Adresse,
							complement2Adresse: valeursFormulaireDemarche.adresseDeContactComplement2Adresse,
							autocompleteAdresse : valeursFormulaireDemarche.adresseIdentiquePourProgrammeCandidats=="Non" ? false : true
						} }
						afficherRNVP={ rnvpOK && (valeursFormulaireDemarche.adresseDeContactPays === "FRANCE") }
						filtreCommune=""
						filtreNumeroDepartement=""
						messageErreurFormulaire={ {
							adresseRedressee: erreurFormulaireDemarche.adresseDeContactAdresseRedressee ? erreurFormulaireDemarche.adresseDeContactAdresseRedressee : "" ,
							codePostal: erreurFormulaireDemarche.adresseDeContactCodePostal ? erreurFormulaireDemarche.adresseDeContactCodePostal : "",
							commune: erreurFormulaireDemarche.adresseDeContactCommune ? erreurFormulaireDemarche.adresseDeContactCommune : "",
							complement1Adresse: erreurFormulaireDemarche.adresseDeContactComplement1Adresse ? erreurFormulaireDemarche.adresseDeContactComplement1Adresse : "",
							complement2Adresse: erreurFormulaireDemarche.adresseDeContactComplement2Adresse ? erreurFormulaireDemarche.adresseDeContactComplement2Adresse : "",
							libelleDeVoie: erreurFormulaireDemarche.adresseDeContactLibelleDeVoie ? erreurFormulaireDemarche.adresseDeContactLibelleDeVoie : "",
							lieuDit: erreurFormulaireDemarche.adresseDeContactLieuDit ? erreurFormulaireDemarche.adresseDeContactLieuDit : "",
							numero: erreurFormulaireDemarche.adresseDeContactNumero ? erreurFormulaireDemarche.adresseDeContactNumero : "",
						} }
						mettreAJourLeChamp={ mettreAJourLeChamp }
						setErreursValeursAdresse={ (erreurs) => {
							setErreursFormulaireDemarche( {
								...erreurFormulaireDemarche,
								adresseDeContactLibelleDeVoie: erreurs.libelleDeVoie,
								adresseDeContactLieuDit: erreurs.lieuDit
							})
						} }
						setValeursAdresse={
							(adresse) => {
								setValeursFormulaireDemarche({
									...valeursFormulaireDemarche,
									adresseDeContactUsagerRemplitSonAdresseManuellement: adresse.usagerRemplitSonAdresseManuellement,
									adresseDeContactAdresseRedressee: adresse.adresseRedressee,
									adresseDeContactCommune: adresse.commune,
									adresseDeContactCodePostal: adresse.codePostal,
									adresseDeContactNumero: adresse.numero,
									adresseDeContactLibelleDeVoie: adresse.libelleDeVoie,
									adresseDeContactLieuDit: adresse.lieuDit,
									adresseDeContactComplement1Adresse: adresse.complement1Adresse,
									adresseDeContactComplement2Adresse: adresse.complement2Adresse
								})
							}
						}
						typeAdresse="adresseDeContact"
					/>
				</Fragment>
			}
			<MiseEnAvant
				couleur={ "clear" }
				icone={ "info" }
			>
				Dans tous les cas vous recevrez <strong>votre carte d’électeur</strong> à votre adresse dans la commune de vote.
			</MiseEnAvant>
			<h3 className="fr-text--lg fr-mb-2w fr-mt-6w">Comment pouvons-nous vous contacter ?</h3>
			<p>Ces informations permettent de <strong>{ "vous contacter" }</strong> et de
				<strong>{ " vous envoyer des notifications de suivi " }</strong>
				de votre démarche
			</p>
			<ChampTextuel
				autoComplete={ "email" }
				id={ "adresseEmail" }
				label="Adresse email personnelle"
				maxLength={ 254 }
				messageErreurFormulaire={ erreurFormulaireDemarche.adresseEmail }
				name={ "adresseEmail" }
				onChange={ mettreAJourLeChampEmail }
				required={ true }
				texteAide="Par exemple : monnom@example.com"
				type="email"
				valeur={ valeursFormulaireDemarche.adresseEmail == "" ? email : valeursFormulaireDemarche.adresseEmail }
				validation={ new AdresseEmailValidation() }
			/>


			<ChampTextuel
				autoComplete={ "tel" }
				id={ "numeroTelephone" }
				label="Numéro de téléphone"
				messageErreurFormulaire={ erreurFormulaireDemarche.numeroTelephone }
				name={ "numeroTelephone" }
				onChange={ mettreAJourLeChamp }
				texteAide="Facultatif - Par exemple : 0123456789 ou 01 23 45 67 89 ou +34 123 456 789"
				type="tel"
				valeur={ valeursFormulaireDemarche.numeroTelephone }
				validation={ new NumeroTelephoneValidation() }
			/>
			<ul className="fr-btns-group fr-mb-2w fr-mt-6w">
				<li>
					<button
						className="fr-btn"
						type="submit"
					>
						{ "Continuer" }
					</button>
				</li>
			</ul>
		</form>
	)
}
export default Formulaire
