import React from "react"
import { Link } from "react-router-dom"
import "./LoggedIn.css"

const LoggedIn: React.FunctionComponent = () => {

	return (
		<div>
			<Link to="/accueil">Revenir à la démarche</Link>
		</div>
	)
}

export default LoggedIn
